import jQuery from 'jquery';
import './style.scss';
//
// import Router from './util/Router';
// import common from './routes/common';
// import home from './routes/home';
//
// /**
//  * Populate Router instance with DOM routes
//  * @type {Router} routes - An instance of our router
//  */
// const routes = new Router({
// 	/** All pages */
// 	common,
// 	/** Home page */
// 	home
// 	/** About Us page, note the change from about-us to aboutUs. */
// });

/** Load Events */
// jQuery(document).ready(() => routes.loadEvents());

// var container = document.querySelector('#masonry-grid');
// var msnry = new Masonry( container, {
//    columnWidth: 495,
//    itemSelector: '.gallery-item'
// });

(function($) {

  $(document).ready(function() {
    // var Swiper = require( './vendor/swiper/js/swiper.js' );

    $('.menu-button, .mobile-menu-close').click(function(){
      $('.mobile-overlay-menu').toggleClass('shown');
      $('body').toggleClass('mobile-menu-shown');
    });
    if ( $('.swiper-container').length )
    {
      var caseStudySlider = new Swiper ('.swiper-container', {
        loop: false,
        slidesPerView: 1,
        direction: 'horizontal',
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      });
    }
		if ( $('.gallery-item').length )
		{
      var current_gallery_item;

			$('.gallery-item').click(function() {
				$('.gallery-lightbox').toggleClass('shown');
        current_gallery_item = $(this);
				var background = $(this).css('background-image');
				$('.gallery-lightbox .image-container').css('background-image',background);
			});
			$('.gallery-lightbox').click(function() {
				$('.gallery-lightbox').toggleClass('shown');
			});
      $('.gallery-previous').click(function(e) {
        e.stopPropagation();
        var next_gallery_item = $('.gallery-item').eq( $('.gallery-item').index( current_gallery_item ) - 1 );
				var background = $(next_gallery_item).css('background-image');
				$('.gallery-lightbox .image-container').css('background-image',background);
        current_gallery_item = next_gallery_item;
      });
      $(document).keydown(function(e){
        if (e.which == 37) {
          // left keydown
          var next_gallery_item = $('.gallery-item').eq( $('.gallery-item').index( current_gallery_item ) - 1 );
  				var background = $(next_gallery_item).css('background-image');
  				$('.gallery-lightbox .image-container').css('background-image',background);
          current_gallery_item = next_gallery_item;
          return false;
        }
      });
      $('.gallery-next').click(function(e) {
        e.stopPropagation();
        var next_gallery_item = $('.gallery-item').eq( $('.gallery-item').index( current_gallery_item ) + 1 );
				var background = $(next_gallery_item).css('background-image');
				$('.gallery-lightbox .image-container').css('background-image',background);
        current_gallery_item = next_gallery_item;
      });
      $(document).keydown(function(e){
        if (e.which == 39) {
          // Right keydown
          var next_gallery_item = $('.gallery-item').eq( $('.gallery-item').index( current_gallery_item ) + 1 );
  				var background = $(next_gallery_item).css('background-image');
  				$('.gallery-lightbox .image-container').css('background-image',background);
          current_gallery_item = next_gallery_item;
          return false;
        }
      });
		}


  });

})(jQuery);
